import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  FileInput,
  FileField,
  ReferenceInput,
  ReferenceField,
  SelectInput,
  useNotify,
  useRedirect,
  useRefresh,
  ImageField,
} from 'react-admin';
import environment from '../config/environment';

const handleSubmit = async (values, notify, redirect, refresh, apiUrl, method) => {
  const formData = new FormData();
  formData.append('name', values.name);
  formData.append('description', values.description);
  formData.append('class_category_id', values.class_category_id);

  if (values.icon instanceof File) {
    formData.append('icon', values.icon, values.icon.name);
  } else if (values.icon && values.icon.rawFile instanceof File) {
    formData.append('icon', values.icon.rawFile, values.icon.rawFile.name);
  }

  if (values.image instanceof File) {
    formData.append('image', values.image, values.image.name);
  } else if (values.image && values.image.rawFile instanceof File) {
    formData.append('image', values.image.rawFile, values.image.rawFile.name);
  }
console.log(formData);

  try {
    const response = await fetch(apiUrl, {
      method: method,
      headers: {
        Accept: 'application/json',
      },
      body: formData,
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    notify('Class modifiée avec succès', { type: 'info' });
    redirect('list', '/classesAdmin');
    refresh();
  } catch (error) {
    notify(`Erreur: ${error.message}`, { type: 'warning' });
    console.error('Failed to edit class:', error);
  }
};

export const ClasseList = props => (
  <List {...props}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="description" />
      <ImageField source="icon" title="icon" />
      <ImageField source="image" title="image" />
      <ReferenceField label="Category" source="class_category_id" reference="class_categoriesAdmin">
        <TextField source="name" />
      </ReferenceField>
      <EditButton />
    </Datagrid>
  </List>
);

export const ClasseEdit = props => {
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();

  return (
    <Edit {...props}>
      <SimpleForm save={values => handleSubmit(values, notify, redirect, refresh, `${environment.apiUrl}/classesAdmin/${values.id}`, 'PUT')}>
        <TextInput source="name" />
        <TextInput source="description" />
        <FileInput source="icon" accept="image/*">
          <FileField source="src" title="title" />
        </FileInput>
        <FileInput source="image" accept="image/*">
          <FileField source="src" title="title" />
        </FileInput>
        <ReferenceInput source="class_category_id" reference="class_categoriesAdmin">
          <SelectInput optionText="name" />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
};

export const ClasseCreate = props => {
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();

  return (
    <Create {...props}>
      <SimpleForm save={values => handleSubmit(values, notify, redirect, refresh, `${environment.apiUrl}/classesAdmin`, 'POST')}>
        <TextInput source="name" />
        <TextInput source="description" />
        <FileInput source="icon" accept="image/*">
          <FileField source="src" title="title" />
        </FileInput>
        <FileInput source="image" accept="image/*">
          <FileField source="src" title="title" />
        </FileInput>
        <ReferenceInput source="class_category_id" reference="class_categoriesAdmin">
          <SelectInput optionText="name" />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};
