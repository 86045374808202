import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  ReferenceInput,
  SelectInput,
  FunctionField,
} from "react-admin";

export const ClassSupplyList = (props) => (
  <List {...props}>
    <Datagrid>
      <TextField source="id" />
      
      {/* Correction pour le champ Supply */}
      <FunctionField
        label="Supply"
        render={(record) => {
          console.log(record);  // Pour vérifier les données dans la console
          return record.supply ? record.supply.name : "";
        }}
      />
      
      {/* Autres champs */}
      <FunctionField
        label="Supply Type"
        render={(record) => (record.supply_type ? record.supply_type.name : "")}
      />
      <FunctionField
        label="School"
        render={(record) =>
          record.school_class && record.school_class.school
            ? record.school_class.school.name
            : ""
        }
      />
      <FunctionField
        label="Class"
        render={(record) =>
          record.school_class && record.school_class.class
            ? record.school_class.class.name
            : ""
        }
      />
      <TextField source="quantity" />
      <EditButton />
    </Datagrid>
  </List>
);

export const ClassSupplyEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <ReferenceInput source="supply_id" reference="suppliesAdmin">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput source="supply_type_id" reference="supply_typesAdmin">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput source="school_class_id" reference="school_classesAdmin">
        <SelectInput
          optionText={(record) => {
            console.log(record);
            return record && record.school && record.class
              ? `${record.school.name} - ${record.class.name}${
                  record.track ? ` - ${record.track.name}` : ""
                }`
              : "Loading...";
          }}
        />
      </ReferenceInput>
      <TextInput source="quantity" />
    </SimpleForm>
  </Edit>
);

export const ClassSupplyCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <ReferenceInput source="supply_id" reference="suppliesAdmin">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput source="supply_type_id" reference="supply_typesAdmin">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput source="school_class_id" reference="school_classesAdmin">
        <SelectInput
          optionText={(record) => {
            console.log(record);
            return record && record.school && record.class
              ? `${record.school.name} - ${record.class.name}${
                  record.track ? ` - ${record.track.name}` : ""
                }`
              : "Loading...";
          }}
        />
      </ReferenceInput>
      <TextInput source="quantity" />
    </SimpleForm>
  </Create>
);
