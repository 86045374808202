import React from 'react';
import { Admin, Resource, nanoLightTheme, nanoDarkTheme } from "react-admin";
import { SchoolList, SchoolEdit, SchoolCreate } from './resources/schools';
import { ClasseList, ClasseEdit, ClasseCreate } from './resources/classes';
import { SchoolClasseList, SchoolClasseEdit, SchoolClasseCreate } from './resources/schoolClasses';
import { SupplyList, SupplyEdit, SupplyCreate } from './resources/supplies';
import { ClassSupplyList, ClassSupplyEdit, ClassSupplyCreate } from './resources/classSupplies';
import { SupplyTypeList, SupplyTypeEdit, SupplyTypeCreate } from './resources/supplyTypes';
import { CompanieList, CompanieEdit, CompanieCreate } from './resources/companie';
import restProvider from "./dataProvider";
import { TrackCreate, TrackEdit, TrackList } from './resources/Track';
import { ClasseCategoryCreate, ClasseCategoryEdit, ClasseCategoryList } from './resources/ClasseCategory';
import { CustomerCreate, CustomerEdit, CustomerList } from './resources/customer';
import { CouponCreate, CouponEdit, CouponList } from './resources/Coupon';
import { DakarCreate, DakarEdit, DakarList } from './resources/dakar';
import { RegionCreate, RegionEdit, RegionList } from './resources/region';
import { PartnerCreate, PartnerEdit, PartnerList } from './resources/partners';
import { RegionPriceCreate, RegionPriceEdit, RegionPriceList } from './resources/region_prices';
import { ZoneCreate, ZoneEdit, ZoneList } from './resources/zones';


const App = () => (
    <Admin dataProvider={restProvider} theme={nanoLightTheme} darkTheme={nanoDarkTheme}>
        <Resource name="schoolsAdmin" list={SchoolList} edit={SchoolEdit} create={SchoolCreate} options={{ label: 'School' }} />
        <Resource name="classesAdmin" list={ClasseList} edit={ClasseEdit} create={ClasseCreate} options={{ label: 'Classes' }} />
        <Resource name="school_classesAdmin" list={SchoolClasseList} edit={SchoolClasseEdit} create={SchoolClasseCreate} options={{ label: 'School Classes' }} />
        <Resource name="suppliesAdmin" list={SupplyList} edit={SupplyEdit} create={SupplyCreate} options={{ label: 'Supplies' }} />
        <Resource name="class_suppliesAdmin" list={ClassSupplyList} edit={ClassSupplyEdit} create={ClassSupplyCreate} options={{ label: 'Classe Supplies' }} />
        <Resource name="companiesAdmin" list={CompanieList} edit={CompanieEdit} create={CompanieCreate} options={{ label: 'Companies' }} />
        <Resource name="tracksAdmin" list={TrackList} edit={TrackEdit} create={TrackCreate} options={{ label: 'Track' }} />
        <Resource name="class_categoriesAdmin" list={ClasseCategoryList} edit={ClasseCategoryEdit} create={ClasseCategoryCreate} options={{ label: 'Class Category' }} />
        <Resource name="supply_typesAdmin" list={SupplyTypeList} edit={SupplyTypeEdit} create={SupplyTypeCreate} options={{ label: 'Types Supplies' }} />
        <Resource name="customersAdmin" list={CustomerList} edit={CustomerEdit} create={CustomerCreate} options={{ label: 'Customer' }} />
        <Resource name="couponAdmin" list={CouponList} edit={CouponEdit} create={CouponCreate} options={{ label: 'Coupon' }} />
        <Resource name="dakarAdmin" list={DakarList} edit={DakarEdit} create={DakarCreate} options={{ label: 'Dakar' }} />
        <Resource name="zoneAdmin" list={ZoneList} edit={ZoneEdit} create={ZoneCreate} options={{ label: 'Zone' }} />
        <Resource name="regionAdmin" list={RegionList} edit={RegionEdit} create={RegionCreate} options={{ label: 'Region' }} />
        <Resource name="region_priceAdmin" list={RegionPriceList} edit={RegionPriceEdit} create={RegionPriceCreate} options={{ label: 'Region Price' }} />
        <Resource name="partnerAdmin" list={PartnerList} edit={PartnerEdit} create={PartnerCreate} options={{ label: 'Partner' }} />

        
    </Admin>
);

export default App;
