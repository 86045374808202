import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  ReferenceInput,
  SelectInput,
  useNotify,
  useRedirect,
  useRefresh,
} from "react-admin";
import environment from '../config/environment';

const handleSubmit = async (values, notify, redirect, refresh, apiUrl, method) => {
  const formData = new FormData();
  formData.append("first_name", values.first_name);
  formData.append("last_name", values.last_name);
  formData.append("companie_id", values.companie_id);
  formData.append("discount_maximum_amount", values.discount_maximum_amount);
  formData.append("matricule", values.matricule);
  formData.append("address", values.address);
  formData.append("phone", values.phone);
  formData.append("email", values.email);

  try {
    const response = await fetch(apiUrl, {
      method: method,
      headers: {
        Accept: "application/json",
      },
      body: formData,
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    notify("Customer saved successfully", { type: "info" });
    redirect("list", "/customersAdmin");
    refresh();
  } catch (error) {
    notify(`Error: ${error.message}`, { type: "warning" });
    console.error("Failed to save customer:", error);
  }
};

export const CustomerList = (props) => (
  <List {...props}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="first_name" />
      <TextField source="last_name" />
      <TextField source="company" />
      <TextField source="discount_maximum_amount" />
      <TextField source="matricule" />
      <TextField source="address" />
      <TextField source="phone" />
      <TextField source="email" />
      <EditButton />
    </Datagrid>
  </List>
);

export const CustomerEdit = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();

  return (
    <Edit {...props}>
      <SimpleForm save={(values) =>
        handleSubmit(values, notify, redirect, refresh, `${environment.apiUrl}/customersAdmin/${values.id}`, "PUT")
      }>
        <TextInput source="first_name" />
        <TextInput source="last_name" />
        <ReferenceInput source="companie_id" reference="companiesAdmin">
          <SelectInput optionText="name" />
        </ReferenceInput>
        <TextInput source="discount_maximum_amount" />
        <TextInput source="matricule" />
        <TextInput source="address" />
        <TextInput source="phone" />
        <TextInput source="email" />
      </SimpleForm>
    </Edit>
  );
};

export const CustomerCreate = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();

  return (
    <Create {...props}>
      <SimpleForm save={(values) =>
        handleSubmit(values, notify, redirect, refresh, `${environment.apiUrl}/customersAdmin`, "POST")
      }>
        <TextInput source="first_name" />
        <TextInput source="last_name" />
        <ReferenceInput source="companie_id" reference="companiesAdmin">
          <SelectInput optionText="name" />
        </ReferenceInput>
        <TextInput source="discount_maximum_amount" />
        <TextInput source="matricule" />
        <TextInput source="address" />
        <TextInput source="phone" />
        <TextInput source="email" />
      </SimpleForm>
    </Create>
  );
};
