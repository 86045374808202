import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  SimpleForm,
  TextInput,
  Create,
} from "react-admin";

export const CouponList = (props) => (
  <List {...props}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="first_name" />
      <TextField source="last_name" />
      <TextField source="company_id" />
      <TextField source="maximum_amount" />
      <TextField source="matricule" />
      <EditButton />
    </Datagrid>
  </List>
);

export const CouponEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="first_name" />
      <TextInput source="last_name" />
      <TextInput source="company_id" />
      <TextInput source="maximum_amount" />
      <TextInput source="matricule" />
    </SimpleForm>
  </Edit>
);

export const CouponCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
    <TextInput source="first_name" />
      <TextInput source="last_name" />
      <TextInput source="company_id" />
      <TextInput source="maximum_amount" />
      <TextInput source="matricule" />
    </SimpleForm>
  </Create>
);
