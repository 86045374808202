import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  FileInput,
  FileField,
  ReferenceInput,
  SelectInput,
  useNotify,
  useRedirect,
  useRefresh,
  ImageField,
  ReferenceField,
} from "react-admin";
import environment from "../config/environment";

const classDefaultValues = () => {
  return { 
          icon: null,
          image: null,
          description: null
        }
}

const handleSubmit = async (values, notify, redirect, refresh, apiUrl, method) => {
  const formData = new FormData();
  formData.append("name", values.name);
  formData.append("description", values.description);
  formData.append("class_id", values.class_id);

  if (values.icon instanceof File) {
    formData.append("icon", values.icon, values.icon.name);
  } else if (values.icon && values.icon.rawFile instanceof File) {
    formData.append("icon", values.icon.rawFile, values.icon.rawFile.name);
  }

  if (values.image instanceof File) {
    formData.append("image", values.image, values.image.name);
  } else if (values.image && values.image.rawFile instanceof File) {
    formData.append("image", values.image.rawFile, values.image.rawFile.name);
  }

  try {
    const response = await fetch(apiUrl, {
      method: method,
      headers: {
        Accept: "application/json",
      },
      body: formData,
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    notify("Track modifié avec succès", { type: "info" });
    redirect("list", "/tracksAdmin");
    refresh();
  } catch (error) {
    notify(`Erreur: ${error.message}`, { type: "warning" });
    console.error("Failed to edit track:", error);
  }
};

export const TrackList = (props) => (
  <List {...props}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="description" />
      <ImageField source="icon" title="icon" />
      <ImageField source="image" title="image" />
      <ReferenceField label="Classe" source="class_id" reference="classesAdmin">
        <TextField source="name" />
      </ReferenceField>
      <EditButton />
    </Datagrid>
  </List>
);

export const TrackEdit = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();

  return (
    <Edit {...props}>
      <SimpleForm defaultValue={classDefaultValues} save={(values) => handleSubmit(values, notify, redirect, refresh, `${environment.apiUrl}/tracksAdmin/${values.id}`, "PUT")}>
        <TextInput source="name" />
        <TextInput source="description" />
        <FileInput source="icon" accept="image/*">
          <FileField source="src" title="title" />
        </FileInput>
        <FileInput source="image" accept="image/*">
          <FileField source="src" title="title" />
        </FileInput>
        <ReferenceInput source="class_id" reference="classesAdmin">
          <SelectInput optionText="name" />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
};

export const TrackCreate = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();

  return (
    <Create {...props}>
      <SimpleForm defaultValue={classDefaultValues} save={(values) => handleSubmit(values, notify, redirect, refresh, `${environment.apiUrl}/tracksAdmin`, "POST")}>
        <TextInput source="name" />
        <TextInput source="description" />
        <FileInput source="icon" accept="image/*">
          <FileField source="src" title="title" />
        </FileInput>
        <FileInput source="image" accept="image/*">
          <FileField source="src" title="title" />
        </FileInput>
        <ReferenceInput source="class_id" reference="classesAdmin">
          <SelectInput optionText="name" />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};
