import React from 'react';
import { List, Datagrid, TextField, EditButton, Edit, SimpleForm, TextInput, Create, ReferenceInput, SelectInput } from 'react-admin';

export const RegionPriceList = (props) => (
    <List {...props}>
        <Datagrid>
            <TextField source="id" />
            <TextField source="region_id" />
            <TextField source="min_weight" />
            <TextField source="max_weight" />
            <TextField source="price" />
            <EditButton />
        </Datagrid>
    </List>
);

export const RegionPriceEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <ReferenceInput source="region_id" reference="regionAdmin">
                <SelectInput optionText="name" />
            </ReferenceInput>
            <TextInput source="min_weight" />
            <TextInput source="max_weight" />
            <TextInput source="price" />
        </SimpleForm>
    </Edit>
);

export const RegionPriceCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <ReferenceInput source="region_id" reference="regionAdmin">
                <SelectInput optionText="name" />
            </ReferenceInput>
            <TextInput source="min_weight" />
            <TextInput source="max_weight" />
            <TextInput source="price" />
        </SimpleForm>
    </Create>
);
