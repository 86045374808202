import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  FileField,
  FileInput,
  ImageField,
  useNotify,
  useRedirect,
  useRefresh
} from 'react-admin';
import environment from '../config/environment';

const handleSubmit = async (values, notify, redirect, refresh, apiUrl, method) => {
  const formData = new FormData();
  formData.append('name', values.name);
  formData.append('description', values.description);

  if (values.icon instanceof File) {
    formData.append('icon', values.icon, values.icon.name);
  } else if (values.icon && values.icon.rawFile instanceof File) {
    formData.append('icon', values.icon.rawFile, values.icon.rawFile.name);
  }

  if (values.image instanceof File) {
    formData.append('image', values.image, values.image.name);
  } else if (values.image && values.image.rawFile instanceof File) {
    formData.append('image', values.image.rawFile, values.image.rawFile.name);
  }

  try {
    const response = await fetch(apiUrl, {
      method: method,
      headers: {
        Accept: 'application/json',
      },
      body: formData,
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    notify('Companie modifiée avec succès', { type: 'info' });
    redirect('list', '/companiesAdmin');
    refresh();
  } catch (error) {
    notify(`Erreur: ${error.message}`, { type: 'warning' });
    console.error('Failed to edit companie:', error);
  }
};

export const CompanieList = props => (
  <List {...props}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="description" />
      <ImageField source="icon" title="icon" />
      <ImageField source="image" title="image" />
      <EditButton />
    </Datagrid>
  </List>
);

export const CompanieEdit = props => {
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();

  return (
    <Edit {...props}>
      <SimpleForm save={values => handleSubmit(values, notify, redirect, refresh, `${environment.apiUrl}/companiesAdmin/${values.id}`, 'PUT')}>
        <TextInput source="name" />
        <TextInput source="description" />
        <FileInput source="icon" accept="image/*">
          <FileField source="src" title="title" />
        </FileInput>
        <FileInput source="image" accept="image/*">
          <FileField source="src" title="title" />
        </FileInput>
      </SimpleForm>
    </Edit>
  );
};

export const CompanieCreate = props => {
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();

  return (
    <Create {...props}>
      <SimpleForm save={values => handleSubmit(values, notify, redirect, refresh, `${environment.apiUrl}/companiesAdmin`, 'POST')}>
        <TextInput source="name" />
        <TextInput source="description" />
        <FileInput source="icon" accept="image/*">
          <FileField source="src" title="title" />
        </FileInput>
        <FileInput source="image" accept="image/*">
          <FileField source="src" title="title" />
        </FileInput>
      </SimpleForm>
    </Create>
  );
};
