import React from 'react';
import { List, Datagrid, TextField, EditButton, Edit, SimpleForm, TextInput, ReferenceInput, SelectInput, Create, ReferenceField } from 'react-admin';

export const DakarList = (props) => (
    <List {...props}>
        <Datagrid>
            <TextField source="id" />
            <TextField source="price" />
            <TextField source="district" />
            <ReferenceField label="Zone" source="zone_id" reference="zoneAdmin">
                <TextField source="name" />
            </ReferenceField>
            <EditButton />
        </Datagrid>
    </List>
);

export const DakarEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="price" />
            <TextInput source="district" />
            <ReferenceInput source="zone_id" reference="zoneAdmin">
                <SelectInput optionText="name" />
            </ReferenceInput>
        </SimpleForm>
    </Edit>
);

export const DakarCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="price" />
            <TextInput source="district" />
            <ReferenceInput source="zone_id" reference="zoneAdmin">
                <SelectInput optionText="name" />
            </ReferenceInput>
        </SimpleForm>
    </Create>
);
