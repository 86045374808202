import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  SimpleForm,
  Create,
  ReferenceInput,
  SelectInput,
  ReferenceField,
  useRefresh,
  useRedirect,
  useNotify,
} from "react-admin";
import environment from "../config/environment";

const classDefaultValues = {
    track_id: 0,
};

const handleSubmit = async (
  values,
  notify,
  redirect,
  refresh,
  apiUrl,
  method
) => {
  const formData = new FormData();

  formData.append("school_id", values.school_id !== undefined ? values.school_id : "");
  formData.append("class_id", values.class_id !== undefined ? values.class_id : "");
  formData.append("class_category_id", values.class_category_id !== undefined ? values.class_category_id : "");
  
  if (values.track_id !== null && values.track_id !== undefined && values.track_id !== 0) {
    formData.append("track_id", values.track_id);
  }

  try {
    const response = await fetch(apiUrl, {
      method: method,
      headers: {
        Accept: "application/json",
      },
      body: formData,
    });

    console.log("Response status:", response.status);
    const responseData = await response.json();
    console.log("Response data:", responseData);

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    notify("SchoolClasse saved successfully", { type: "info" });
    redirect("list", "/school_classesAdmin");
    refresh();
  } catch (error) {
    notify(`Error: ${error.message}`, { type: "warning" });
    console.error("Failed to save SchoolClasse:", error);
  }
};

export const SchoolClasseList = (props) => (
  <List {...props}>
    <Datagrid>
      <TextField source="id" />
      <ReferenceField label="School" source="school_id" reference="schools">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField label="Class" source="class_id" reference="classes">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField label="Category" source="class_category_id" reference="class_categories">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField label="Track" source="track_id" reference="tracks">
        <TextField source="name" />
      </ReferenceField>
      <EditButton />
    </Datagrid>
  </List>
);

export const SchoolClasseEdit = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();

  return (
    <Edit {...props}>
      <SimpleForm
        defaultValue={classDefaultValues}
        save={(values) =>
          handleSubmit(
            values,
            notify,
            redirect,
            refresh,
            `${environment.apiUrl}/school_classesAdmin/${props.id}`,
            "PUT"
          )
        }
      >
        <ReferenceInput source="school_id" reference="schools">
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput source="class_id" reference="classes">
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput source="class_category_id" reference="class_categories">
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput source="track_id" reference="tracks">
          <SelectInput optionText="name" />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
};

export const SchoolClasseCreate = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();

  return (
    <Create {...props}>
      <SimpleForm
        defaultValues={classDefaultValues}
        save={(values) =>
          handleSubmit(
            values,
            notify,
            redirect,
            refresh,
            `${environment.apiUrl}/school_classesAdmin`,
            "POST"
          )
        }
      >
        <ReferenceInput source="school_id" reference="schools">
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput source="class_id" reference="classes">
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput source="class_category_id" reference="class_categories">
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput source="track_id" reference="tracks">
          <SelectInput optionText="name" />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};
