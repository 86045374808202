import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  FileInput,
  FileField,
  useNotify,
  useRefresh,
  useRedirect,
  ImageField,
} from "react-admin";
import environment from "../config/environment";

export const ClasseCategoryList = (props) => (
  <List {...props}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="description" />
      <ImageField source="icon" title="Icon" />
      <ImageField source="image" title="Image" />
      <EditButton />
    </Datagrid>
  </List>
);

const handleSubmit = async (
    values,
    notify,
    redirect,
    refresh,
    apiUrl,
    method
  ) => {
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("description", values.description);
  
    if (values.icon instanceof File) {
      formData.append("icon", values.icon, values.icon.name);
    } else if (values.icon && values.icon.rawFile instanceof File) {
      formData.append("icon", values.icon.rawFile, values.icon.rawFile.name);
    }
  
    if (values.image instanceof File) {
      formData.append("image", values.image, values.image.name);
    } else if (values.image && values.image.rawFile instanceof File) {
      formData.append("image", values.image.rawFile, values.image.rawFile.name);
    }
  
    // Log the form data values to the console
    console.log('Form Data Entries:');
    for (let [key, value] of formData.entries()) {
      console.log(`${key}:`, value);
    }
  
    try {
      const response = await fetch(apiUrl, {
        method: method,
        headers: {
          Accept: "application/json",
        },
        body: formData,
      });
  
      // Log the response status and data to the console
      console.log('Response Status:', response.status);
      console.log('Response Data:', await response.json());
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      notify("Category class modifié avec succès", { type: "info" });
      redirect("list", "/class_categoriesAdmin");
      refresh();
    } catch (error) {
      notify(`Erreur: ${error.message}`, { type: "warning" });
      console.error("Failed to edit category:", error);
    }
  };
  

export const ClasseCategoryEdit = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();

  return (
    <Edit {...props}>
      <SimpleForm
        save={(values) =>
          handleSubmit(
            values,
            notify,
            redirect,
            refresh,
            `${environment.apiUrl}/class_categoriesAdmin/${values.id}`,
            "PUT"
          )
        }
      >
        <TextInput source="name" />
        <TextInput source="description" />
        <FileInput source="icon" accept="image/*">
          <FileField source="src" title="title" />
        </FileInput>
        <FileInput source="image" accept="image/*">
          <FileField source="src" title="title" />
        </FileInput>
      </SimpleForm>
    </Edit>
  );
};

export const ClasseCategoryCreate = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();

  return (
    <Create {...props}>
      <SimpleForm
        save={(values) =>
          handleSubmit(
            values,
            notify,
            redirect,
            refresh,
            `${environment.apiUrl}/class_categoriesAdmin`,
            "POST"
          )
        }
      >
        <TextInput source="name" />
        <TextInput source="description" />
        <FileInput source="icon" accept="image/*">
          <FileField source="src" title="title" />
        </FileInput>
        <FileInput source="image" accept="image/*">
          <FileField source="src" title="title" />
        </FileInput>
      </SimpleForm>
    </Create>
  );
};
